var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"regist-box"},[_c('a-form',{staticClass:"login-form",attrs:{"form":_vm.form,"auto-complete":"newpassword"},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-tooltip',{attrs:{"placement":"right"}},[_c('template',{slot:"title"},[_c('span',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t("useNameTip")))])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'username',
            {
              rules: [
                {
                  required: true,
                  message: this.$t('userRule'),
                },
                {
                  pattern: /^[^\s]*$/,
                  message: this.$t('notEmpty'),
                },
                {
                  pattern:
                    /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![\u4e00-\u9fa5a]+$)(?![-_]+$)[\u4e00-\u9fa5a-zA-Z0-9_-]{4,15}$/,
                  // pattern: /^(?![0-9]+$)[\u4e00-\u9fa5a-zA-Z0-9_-]{4,15}$/,
                  message: this.$t('userNameRule'),
                } ],
            } ]),expression:"[\n            'username',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: this.$t('userRule'),\n                },\n                {\n                  pattern: /^[^\\s]*$/,\n                  message: this.$t('notEmpty'),\n                },\n                {\n                  pattern:\n                    /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![\\u4e00-\\u9fa5a]+$)(?![-_]+$)[\\u4e00-\\u9fa5a-zA-Z0-9_-]{4,15}$/,\n                  // pattern: /^(?![0-9]+$)[\\u4e00-\\u9fa5a-zA-Z0-9_-]{4,15}$/,\n                  message: this.$t('userNameRule'),\n                },\n              ],\n            },\n          ]"}],ref:"userName",staticStyle:{"line-height":"40px","width":"80%"},attrs:{"read-only":_vm.readonlyInput,"placeholder":this.$t('placeholderRegistUser')},on:{"focus":function($event){return _vm.cancelReadOnly()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)},"blur":_vm.checkUsernameUnique}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],2)],1),_c('a-form-item',{staticClass:"phoneClass"},[_c('a-tooltip',{attrs:{"placement":"right"}},[_c('template',{slot:"title"},[_c('span',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t("phoneTip")))])]),_c('a-input-group',{staticStyle:{"line-height":"40px","width":"80%"},attrs:{"compact":""}},[_c('a-select',{staticStyle:{"line-height":"40px","width":"25%","height":"40px"},attrs:{"default-value":_vm.beare},on:{"change":_vm.handleChange}},_vm._l((_vm.areaCode),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.head)+" ")])}),1),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'phone',
              {
                rules: [
                  {
                    required: true,
                    message: this.$t('phoneRule'),
                  },
                  {
                    // pattern: /^[1][3,4,5,7,8][0-9]{9}$/,
                    pattern: _vm.regRule,
                    message: this.$t('phonetrue'),
                  },
                  {
                    pattern: /^[^\s]*$/,
                    message: this.$t('notEmpty'),
                  } ],
              } ]),expression:"[\n              'phone',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: this.$t('phoneRule'),\n                  },\n                  {\n                    // pattern: /^[1][3,4,5,7,8][0-9]{9}$/,\n                    pattern: regRule,\n                    message: this.$t('phonetrue'),\n                  },\n                  {\n                    pattern: /^[^\\s]*$/,\n                    message: this.$t('notEmpty'),\n                  },\n                ],\n              },\n            ]"}],ref:"phone",staticStyle:{"line-height":"40px","width":"75%"},attrs:{"validateFirst":"","placeholder":this.$t('placeholderRegistPhone')},on:{"blur":_vm.checkUserMailUnique}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"phone"},slot:"prefix"})],1)],1)],2)],1),_c('a-form-item',[_c('div',{staticClass:"codeinput"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'code',
            {
              rules: [
                {
                  required: true,
                  message: this.$t('codeRule'),
                },
                {
                  len: 4,
                  message: this.$t('codeLenRule'),
                },
                {
                  pattern: /^[^\s]*$/,
                  message: this.$t('notEmpty'),
                } ],
            } ]),expression:"[\n            'code',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: this.$t('codeRule'),\n                },\n                {\n                  len: 4,\n                  message: this.$t('codeLenRule'),\n                },\n                {\n                  pattern: /^[^\\s]*$/,\n                  message: this.$t('notEmpty'),\n                },\n              ],\n            },\n          ]"}],staticStyle:{"width":"72%"},attrs:{"placeholder":this.$t('placeholderRegistCode')}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"safety-certificate"},slot:"prefix"})],1),(_vm.sendAuthCode)?_c('a-button',{staticClass:"codeimg",attrs:{"type":"primary"},on:{"click":_vm.sendEmailCode}},[_vm._v(" "+_vm._s(_vm.$t("getCode"))+" ")]):_c('a-button',{staticClass:"codeimg",attrs:{"disabled":"","type":"primary"}},[_vm._v(" "+_vm._s(_vm.auth_time)+"s ")])],1)]),_c('a-form-item',[_c('a-tooltip',{attrs:{"placement":"right"}},[_c('template',{slot:"title"},[_c('span',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t("passwordTip")))])]),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'userPwd',
            {
              rules: [
                {
                  required: true,
                  message: this.$t('passwordRule'),
                },
                {
                  validator: _vm.validateToNextPassword,
                },
                {
                  pattern: /^[^\s]*$/,
                  message: this.$t('notEmpty'),
                },
                {
                  pattern:  /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![\u4e00-\u9fa5a]+$)(?![-_]+$)[\u4e00-\u9fa5a-zA-Z0-9_-]{8,32}$/,
                  message: this.$t('userPwdRule'),
                } ],
            } ]),expression:"[\n            'userPwd',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: this.$t('passwordRule'),\n                },\n                {\n                  validator: validateToNextPassword,\n                },\n                {\n                  pattern: /^[^\\s]*$/,\n                  message: this.$t('notEmpty'),\n                },\n                {\n                  pattern:  /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![\\u4e00-\\u9fa5a]+$)(?![-_]+$)[\\u4e00-\\u9fa5a-zA-Z0-9_-]{8,32}$/,\n                  message: this.$t('userPwdRule'),\n                },\n              ],\n            },\n          ]"}],staticStyle:{"line-height":"40px","width":"80%"},attrs:{"type":"password","placeholder":this.$t('placeholderRegistPwd')}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],2)],1),_c('a-form-item',[_c('a-tooltip',{attrs:{"placement":"right"}},[_c('template',{slot:"title"},[_c('span',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t("confirmTip")))])]),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'confirm',
            {
              rules: [
                {
                  required: true,
                  message: this.$t('confirmPasswordRule'),
                },
                {
                  validator: _vm.compareToFirstPassword,
                },
                {
                  pattern: /^[^\s]*$/,
                  message: this.$t('notEmpty'),
                } ],
            } ]),expression:"[\n            'confirm',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: this.$t('confirmPasswordRule'),\n                },\n                {\n                  validator: compareToFirstPassword,\n                },\n                {\n                  pattern: /^[^\\s]*$/,\n                  message: this.$t('notEmpty'),\n                },\n              ],\n            },\n          ]"}],staticStyle:{"line-height":"40px","width":"80%"},attrs:{"type":"password","placeholder":this.$t('placeholderRegistPwd2')},on:{"blur":_vm.handleConfirmBlur}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],2)],1),_c('a-form-item',[_c('a-checkbox',{attrs:{"checked":_vm.checked},on:{"change":_vm.onChange}}),_c('span',{staticClass:"prispan",on:{"click":_vm.showModal}},[_vm._v("  "+_vm._s(_vm.$t("privacyPolicy")))])],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit","disabled":_vm.disableConfirm}},[_vm._v(" "+_vm._s(_vm.$t("registCount"))+" ")])],1)],1),_c('div',[_c('a-modal',{staticClass:"primodal",attrs:{"ok-text":"确认","cancel-text":"取消"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('iframe',{staticClass:"ifclass",attrs:{"src":_vm.privacyUrl},on:{"load":_vm.onloads}}),_c('template',{slot:"footer"},[_c('a-button',{key:"back",attrs:{"type":"primary"},on:{"click":_vm.agreehideModal}},[_vm._v(" "+_vm._s(_vm.$t("agree"))+" ")]),_c('a-button',{key:"submit",on:{"click":_vm.hideModal}},[_vm._v(" "+_vm._s(_vm.$t("notAgree"))+" ")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }