<template>
  <div id="components-layout-demo-basic">
    <a-layout>
      <a-layout-header>
        <Header />
      </a-layout-header>
      <a-layout-content>
        <div class="slider-img-box">
          <!-- <div><img src="@/assets/image/bg-Highlight.png" alt="" /></div> -->
          <div class="imgclass">
              <img src="../../assets/image/loginbg.png" alt="" />
            </div>
          <div class="login">
            <div style="height:100%;position:relative">
              <div class="loginclass">
                <p>{{ $t("userRegist") }}</p>
              </div>
              <a-tabs default-active-key="1"  @change="changetab">
                <a-tab-pane  key="1" :tab="$t('emailRegistration')"><RegistEmail :paramdata="paramdata" /> </a-tab-pane>
                <a-tab-pane key="2" :tab="$t('mobileRegistration')"> <RegistPhone :paramdata="paramdata" /> </a-tab-pane>
              </a-tabs>
              <div class="regist">
                <router-link to="/">{{ $t(`hadCount`) }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </a-layout-content>
      <a-layout-footer>
        <Footer />
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>
import Header from "../modules/header.vue";
import Footer from "../modules/footer.vue";
import RegistEmail from "./modules/regist-email.vue";
import RegistPhone from "./modules/regist-phone.vue";

export default {
  components: { Header, Footer, RegistEmail, RegistPhone },
  data() {
    return {
      paramdata:""
    };
  },
  created() {},

  methods: {
    changetab(activeKey){
      this.paramdata = activeKey
    }
  },
};
</script>

<style scoped>
/* 头部 */
#components-layout-demo-basic .ant-layout-header {
  width: 100%;
  min-height: 64px;
  padding: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
}
#components-layout-demo-basic .ant-layout-header > h1 {
  margin: 0;
  text-align: center;
  vertical-align: middle;
}

/* 内容 */
#components-layout-demo-basic .ant-layout-content {
  text-align: center;
}
.loginclass {
  margin-left: 50px;
  text-align: left;
  font-size: 30px;
  height: 40px;
  margin-top: 15px;
  color: #000;
  margin-bottom: 10px;
}
.slider-img-box {
  /* background-color: #409eff; */
  display: flex;
  background-color: #f8f8f8;
  width: 100vw;
  height: calc(100vh - 128px);
  justify-content: center;
  align-items: center;
}
/* .slider-img-box img {
  position: relative;
  max-width: 100%;
  width: 100vw;
  height: calc(100vh - 128px);
} */
.login {
  width: 27vw;
  min-width: 510px;
  height: 570px;
  background-color: #fff;
}
.login >>> .ant-tabs-nav {
  font-size: 18px;
}
.login >>> .ant-tabs-bar{
  border-bottom: none;
  display: flex;
  padding-left: 10%;
}
.login >>> .ant-tabs-tab{
  font-size: 14px;
  padding: 0;
}
.regist {
  display: flex;
  justify-content: center;
  padding: 2% 5%;
  padding-left: 9.4%;
  padding-top: 4%;
  margin-left: 10%;
  margin-right: 10%;
  border-top:1px solid #DDDDDD ;
  position: absolute;
  width: 80%;
  bottom: 0;
  padding-bottom: 3%;
  /* float: left; */
}

/* 底部 */
#components-layout-demo-basic .ant-layout-footer {
  background-color: #fff;
  padding: 0;
}
.imgclass {
  width: 26vw;
  min-width: 490px;
  height: 570px;
}
.imgclass img {
  width: 26vw;
  min-width: 490px;
  height: 570px;
}
</style>
