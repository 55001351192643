<template>
  <div class="regist-box">
    <a-form
      :form="form"
      class="login-form"
      @submit="handleSubmit"
      auto-complete="newpassword"
    >
      <!-- 输入用户名 -->
      <a-form-item>
        <a-tooltip placement="right">
          <template slot="title"
            ><span class="tips">{{ $t(`useNameTip`) }}</span>
          </template>
          <!--  :read-only="readonlyInput" @focus="cancelReadOnly()" -->
          <a-input
            :read-only="readonlyInput"
            @focus="cancelReadOnly()"
            @keyup.enter="handleSubmit"
            @blur="checkUsernameUnique"
            ref="userName"
            v-decorator="[
              'username',
              {
                rules: [
                  {
                    required: true,
                    message: this.$t('userRule'),
                  },
                  {
                    pattern: /^[^\s]*$/,
                    message: this.$t('notEmpty'),
                  },
                  {
                    pattern:
                      /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![\u4e00-\u9fa5a]+$)(?![-_]+$)[\u4e00-\u9fa5a-zA-Z0-9_-]{4,15}$/,
                    // pattern: /^(?![0-9]+$)[\u4e00-\u9fa5a-zA-Z0-9_-]{4,15}$/,
                    message: this.$t('userNameRule'),
                  },
                ],
              },
            ]"
            :placeholder="this.$t('placeholderRegistUser')"
            style="line-height: 40px; width: 80%"
          >
            <a-icon
              slot="prefix"
              type="user"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-tooltip>
      </a-form-item>
      <!-- 手机号 -->
      <a-form-item class="phoneClass">
        <a-tooltip placement="right">
          <template slot="title">
            <span class="tips">{{ $t(`phoneTip`) }}</span>
          </template>
          <a-input-group compact style="line-height: 40px; width: 80%">
            <a-select
              :default-value="beare"
              style="line-height: 40px; width: 25%; height: 40px"
              @change="handleChange"
            >
              <a-select-option
                :value="item.id"
                v-for="(item, index) in areaCode"
                :key="index"
              >
                {{ item.head }}
              </a-select-option>
            </a-select>
            <a-input
              @blur="checkUserMailUnique"
              ref="phone"
              validateFirst
              v-decorator="[
                'phone',
                {
                  rules: [
                    {
                      required: true,
                      message: this.$t('phoneRule'),
                    },
                    {
                      // pattern: /^[1][3,4,5,7,8][0-9]{9}$/,
                      pattern: regRule,
                      message: this.$t('phonetrue'),
                    },
                    {
                      pattern: /^[^\s]*$/,
                      message: this.$t('notEmpty'),
                    },
                  ],
                },
              ]"
              :placeholder="this.$t('placeholderRegistPhone')"
              style="line-height: 40px; width: 75%"
            >
              <a-icon
                slot="prefix"
                type="phone"
                style="color: rgba(0, 0, 0, 0.25)"
              />
            </a-input>
          </a-input-group>
        </a-tooltip>
      </a-form-item>
      <!-- 验证码 -->
      <a-form-item>
        <div class="codeinput">
          <a-input
            v-decorator="[
              'code',
              {
                rules: [
                  {
                    required: true,
                    message: this.$t('codeRule'),
                  },
                  {
                    len: 4,
                    message: this.$t('codeLenRule'),
                  },
                  {
                    pattern: /^[^\s]*$/,
                    message: this.$t('notEmpty'),
                  },
                ],
              },
            ]"
            :placeholder="this.$t('placeholderRegistCode')"
            style="width: 72%"
          >
            <a-icon
              slot="prefix"
              type="safety-certificate"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
          <a-button
            v-if="sendAuthCode"
            @click="sendEmailCode"
            class="codeimg"
            type="primary"
          >
            {{ $t(`getCode`) }}
          </a-button>
          <a-button v-else disabled class="codeimg" type="primary">
            {{ auth_time }}s
          </a-button>
        </div>
      </a-form-item>
      <!-- 输入密码 -->
      <a-form-item>
        <a-tooltip placement="right">
          <template slot="title"
            ><span class="tips">{{ $t(`passwordTip`) }}</span>
          </template>
          <a-input-password
            v-decorator="[
              'userPwd',
              {
                rules: [
                  {
                    required: true,
                    message: this.$t('passwordRule'),
                  },
                  {
                    validator: validateToNextPassword,
                  },
                  {
                    pattern: /^[^\s]*$/,
                    message: this.$t('notEmpty'),
                  },
                  {
                    pattern:  /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![\u4e00-\u9fa5a]+$)(?![-_]+$)[\u4e00-\u9fa5a-zA-Z0-9_-]{8,32}$/,
                    message: this.$t('userPwdRule'),
                  },
                ],
              },
            ]"
            type="password"
            :placeholder="this.$t('placeholderRegistPwd')"
            style="line-height: 40px; width: 80%"
          >
            <a-icon
              slot="prefix"
              type="lock"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input-password>
        </a-tooltip>
      </a-form-item>
      <!-- 确认密码 -->
      <a-form-item>
        <a-tooltip placement="right">
          <template slot="title"
            ><span class="tips">{{ $t(`confirmTip`) }}</span>
          </template>
          <a-input-password
            v-decorator="[
              'confirm',
              {
                rules: [
                  {
                    required: true,
                    message: this.$t('confirmPasswordRule'),
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                  {
                    pattern: /^[^\s]*$/,
                    message: this.$t('notEmpty'),
                  },
                ],
              },
            ]"
            type="password"
            :placeholder="this.$t('placeholderRegistPwd2')"
            @blur="handleConfirmBlur"
            style="line-height: 40px; width: 80%"
          >
            <a-icon
              slot="prefix"
              type="lock"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input-password>
        </a-tooltip>
      </a-form-item>
      <a-form-item>
        <a-checkbox @change="onChange" :checked="checked"> </a-checkbox>
        <span class="prispan" @click="showModal"
          >&nbsp;&nbsp;{{ $t(`privacyPolicy`) }}</span
        >
      </a-form-item>
      <a-form-item>
        <a-button
          type="primary"
          html-type="submit"
          class="login-form-button"
          :disabled="disableConfirm"
        >
          {{ $t(`registCount`) }}
        </a-button>
      </a-form-item>
    </a-form>

    <div>
      <a-modal
        class="primodal"
        v-model="visible"
        ok-text="确认"
        cancel-text="取消"
      >
        <iframe @load="onloads" class="ifclass" :src="privacyUrl"></iframe>
        <template slot="footer">
          <a-button key="back" @click="agreehideModal" type="primary">
            {{ $t(`agree`) }}
          </a-button>
          <a-button key="submit" @click="hideModal">
            {{ $t(`notAgree`) }}
          </a-button>
        </template>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { showmessage } from "../../../utils/showMessage";
import { selectlan } from "@/utils/selectLanguage";
import { encrypt } from "@/utils/aes";
export default {
  name: "",
  components: {},
  props: {
    paramdata: {
      type: String,
      defalut: "",
    },
  },
  data() {
    return {
      readonlyInput: true,
      beare: "+86",
      regRule: "",
      areaCode: [],
      privacyUrl:
        "https://privacy.xmeye.net/xmeye/privacy_" +
        sessionStorage.getItem("languagevalue") +
        ".html",
      visible: false,
      checked: false,
      languagevalue: sessionStorage.getItem("languagevalue"),
      auth_time: 0,
      sendAuthCode: true,
      disableConfirm: true,
      value: ["china"],
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "register" });
  },
  computed: {
    changeLan: function () {
      return this.$store.state.changeLan;
    },
    changeData: function () {
      return this.paramdata;
    },
  },

  watch: {
    changeLan() {
      this.form.resetFields();
      this.privacyUrl =
        "https://privacy.xmeye.net/xmeye/privacy_" +
        sessionStorage.getItem("languagevalue") +
        ".html";
    },
    value(val) {
      console.log(`selected:`, val);
    },
    changeData() {
      this.form.resetFields();
    },
  },
  mounted() {
    // 获取区号接口
    let that = this;
    that.$http.post("/transfer/phoneSupport/v1", {}).then((res) => {
      if (res) {
        if (res.code != undefined && res.code != null && res.code == 2000) {
          console.log(res.data,'res.data')
          that.areaCode =res.data //多地区区号显示
          that.regRule = that.areaCode[0].rule;
          that.beare = that.areaCode[0].head;
          if (res.code == 4012) {
            this.$message.destroy();
            this.$message.error(this.$t("hadUser"));
          }
        } else {
          this.$message.destroy();
          this.$message.error(this.$t("" + showmessage(res)));
        }
      } else {
        this.$message.destroy();
        this.$message.error(this.$t("getareacodefail"));
      }
    });
  },
  destroyed() {},
  methods: {
    cancelReadOnly() {
      this.readonlyInput = false;
    },
    onloads() {
      var isRunUrl = function (url) {
        return new Promise(function (resolve, reject) {
          // 测试链接连通性, 主要检测404错误
          // 由于AJAX通常无法区分404和跨域问题
          // 所以只能用script 或者 link标签
          // link比script更容易捕获错误
          var dom = document.createElement("link");
          dom.href = url;
          dom.rel = "stylesheet";
          document.head.appendChild(dom);
          dom.onload = function () {
            document.head.removeChild(dom);
            resolve();
          };
          dom.onerror = reject;
        });
      };

      let that = this;
      isRunUrl(this.privacyUrl).then(
        function (data) {
          //处理resolve的代码
          console.log("Promise被置为resolve", data);
        },
        function (data) {
          //处理reject的代码
          console.log("程序被置为了reject", data);
          that.privacyUrl = "https://privacy.xmeye.net/xmeye/privacy_en.html";
        }
      );
    },

    // 切换区号修改电话号码的正则
    handleChange(value) {
      // console.log(value,this.areaCode,sessionStorage.getItem("languagevalue"),'handlechange')
      //如果需要判定当地区号的话 可能需要判断remark
      this.areaCode.forEach((item) => {
        if (value == item.id) {
          this.regRule = item.rule;
          console.log(item,item.rule,'item.rule')
        }
      });
    },
    showModal() {
      this.visible = true;
      this.onloads();
    },
    hideModal() {
      this.visible = false;
      this.checked = false;
      this.disableConfirm = !this.checked;
    },
    agreehideModal() {
      this.visible = false;
      this.checked = true;
      this.disableConfirm = !this.checked;
    },
    //
    onChange() {
      this.checked = !this.checked;
      this.disableConfirm = !this.checked;
    },
    //验证用户名唯一性
    checkUsernameUnique() {
      if (
        this.$refs.userName.value !== "" &&
        this.$refs.userName.value !== null &&
        this.$refs.userName.value !== undefined
      ) {
        let userName = this.$refs.userName.value;
        this.$http
          .post("/transfer/usercheck/v1", { name: encrypt(userName) })
          .then((res) => {
            if (res) {
              if (res.code == 4012) {
                this.$message.destroy();
                this.$message.error(this.$t("hadUser"));
              }
            } else {
              this.$message.destroy();
              this.$message.error(this.$t("rulenamefail"));
            }
          });
      }
    },
    // 验证电话唯一
    checkUserMailUnique() {
      if (
        this.$refs.phone.value !== "" &&
        this.$refs.phone.value !== null &&
        this.$refs.phone.value !== undefined
      ) {
        let phone = this.$refs.phone.value;

        this.$http
          .post("/transfer/usercheckPhone/v1", {
            phone: encrypt(phone),
          })
          .then((res) => {
            if (res) {
              if (res.code == 4022) {
                // 4022 手机号未注册
                // this.$message.destroy();
                // this.$message.success(this.$t(""+showmessage(res)));
              } else {
                this.$message.destroy();
                this.$message.error(this.$t("" + showmessage(res)));
              }
            } else {
              this.$message.destroy();
              this.$message.error(this.$t("rulephonefail"));
            }
          });
      }
    },
    // 发送验证码
    sendEmailCode() {
      if (
        this.$refs.phone.value !== "" &&
        this.$refs.phone.value !== null &&
        this.$refs.phone.value !== undefined
      ) {
        let phone = this.$refs.phone.value;
        this.$http
          .post("/transfer/scphone/v1", {
            phone: encrypt(phone),
            language: selectlan(),
            type: "re",
          })
          .then((res) => {
            if (res) {
              if (res.code == 2000) {
                this.$message.destroy();
                this.$message.success(this.$t("phoneSendSuccess"));
                this.sendAuthCode = false;
                this.auth_time = 120;
                var auth_timetimer = setInterval(() => {
                  this.auth_time--;
                  if (this.auth_time <= 0) {
                    this.sendAuthCode = true;
                    clearInterval(auth_timetimer);
                  }
                }, 1000);
              } else if (res.code == 5005) {
                this.$message.destroy();
                this.$message.error(this.$t("phoneFormatError"));
              } else if (res.code == 4023) {
                this.$message.destroy();
                this.$message.error(this.$t("hadPhone"));
              } else if (res.code == 4402) {
                this.$message.destroy();
                this.$message.error(this.$t("mailSendMuch"));
              } else {
                this.$message.destroy();
                this.$message.error(this.$t("" + showmessage(res)));
              }
            } else {
              this.$message.destroy();
              this.$message.error(this.$t("getcodefail"));
            }
          });
      } else {
        this.$message.destroy();
        this.$message.error(this.$t("phoneRule"));
      }
    },
    // 注册
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let send = {
            name: encrypt(values.username),
            phone: encrypt(values.phone),
            verCode: values.code,
            confirmpass: encrypt(values.confirm),
            password: encrypt(values.userPwd),
          };

          this.$http.post("/transfer/userregPhone/v1", send).then((res) => {
            if (res) {
              if (res.code == 2000) {
                this.$message.destroy();
                this.$message.success(this.$t("registerSuccess"));
                this.$router.push({ path: "/" });
              } else if (res.code == 4010) {
                this.$message.destroy();
                this.$message.error(this.$t("codeError"));
              } else if (res.code == 4012) {
                this.$message.destroy();
                this.$message.error(this.$t("hadUser"));
              } else if (res.code == 4017) {
                this.$message.destroy();
                this.$message.error(this.$t("" + showmessage(res)));
              } else if (res.code === 4048) {
                this.$message.destroy();
                this.$message.error(this.$t("codeErrorMuch"));
              } else {
                this.$message.destroy();
                this.$message.error(this.$t("" + showmessage(res)));
              }
            } else {
              this.$message.destroy();
              this.$message.error(this.$t("ruleregisterfail"));
            }
          });
        }
      });
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("userPwd")) {
        callback(this.$t(`confirmTip`));
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      if (value && this.confirmDirty) {
        form.validateFields(["confirm"], { force: true });
      }
      callback();
    },
  },
};
</script>

<style scoped>
.regist-box >>> .ant-input-affix-wrapper .ant-input {
  text-align: left !important;
}
.regist-box >>> .ant-input-group {
  display: flex;
}
.regist-box >>> .phoneClass .ant-select-selection {
  height: 40px;
}
.regist-box
  >>> .phoneClass
  .ant-select-selection
  .ant-select-selection__rendered {
  height: 40px;
  line-height: 40px;
}

.codeinput {
  width: 80%;
}
.primodal >>> .ant-modal {
  width: 100% !important;
  height: 98%;
  position: relative;
  top: 0px;
  /* overflow: auto; */
}
.primodal >>> .ant-modal .ant-modal-close {
  display: none;
}
.primodal >>> .ant-modal-body {
  padding: 0;
}
.primodal >>> .ant-modal-footer {
  border-top-width: 0px;
}
.prispan {
  color: #1890ff;
  cursor: pointer;
}
.ifclass {
  width: 100%;
  height: 94vh;
  overflow: auto;
}
.codeinput >>> .ant-input-affix-wrapper {
  float: left;
}
.codeimg {
  width: 104px;
  height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: right;
}
.regist-box >>> .ant-tabs-nav-container {
  font-size: 20px;
}
.regist-box >>> .ant-form-explain {
  text-align: left;
  margin-left: 10%;
}
.regist-box >>> .ant-form-item {
  margin-bottom: 7px;
}
.regist-box >>> .ant-form-item-children {
  display: flex;
  justify-content: center;
  height: 40px !important;
  align-items: center;
}
.regist-box >>> .ant-input {
  height: 40px !important;
  font-size: 16px;
}
.login-form-button {
  height: 40px !important;
  width: 80%;
  font-size: 16px !important;
}
.tips {
  display: block;
  font-size: 13px;
  max-width: 185px;
}
</style>
