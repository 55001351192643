<template>
  <div class="regist-box">
    <a-form
      :form="form"
      class="login-form"
      @submit="handleSubmit"
      autocomplete="off"
    >
      <!-- 输入用户名 -->
      <a-form-item>
        <a-tooltip placement="right">
          <template slot="title"
            ><span class="tips">{{ $t(`useNameTip`) }}</span>
          </template>
          <a-input
            :read-only="readonlyInput"
            @focus="cancelReadOnly()"
            @keyup.enter="handleSubmit"
            @blur="checkUsernameUnique"
            ref="userName"
            v-decorator="[
              'username',
              {
                rules: [
                  {
                    required: true,
                    message: this.$t('userRule'),
                  },
                  {
                    pattern: /^[^\s]*$/,
                    message: this.$t('notEmpty'),
                  },
                  {
                    // pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,10}$/,
                    pattern:
                      /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![\u4e00-\u9fa5a]+$)(?![-_]+$)[\u4e00-\u9fa5a-zA-Z0-9_-]{4,15}$/,
                    message: this.$t('userNameRule'),
                  },
                ],
              },
            ]"
            :placeholder="this.$t('placeholderRegistUser')"
            style="line-height: 40px; width: 80%"
          >
            <a-icon
              slot="prefix"
              type="user"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-tooltip>
      </a-form-item>
      <!-- 邮箱 -->
      <a-form-item>
        <a-tooltip placement="right">
          <template slot="title"
            ><span class="tips">{{ $t(`emailTip`) }}</span>
          </template>
          <a-input
            @blur="checkUserMailUnique"
            ref="email"
            v-decorator="[
              'userMail',
              {
                rules: [
                  {
                    type: 'email',
                    message: this.$t('emailtrue'),
                  },
                  {
                    required: true,
                    message: this.$t('emailRule'),
                  },
                  {
                    pattern: /^[^\s]*$/,
                    message: this.$t('notEmpty'),
                  },
                ],
              },
            ]"
            :placeholder="this.$t('placeholderRegistMail')"
            style="line-height: 40px; width: 80%"
          >
            <a-icon
              slot="prefix"
              type="mail"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-tooltip>
      </a-form-item>
      <!-- 验证码 -->
      <a-form-item>
        <div class="codeinput">
          <a-input
            v-decorator="[
              'code',
              {
                rules: [
                  {
                    required: true,
                    message: this.$t('codeRule'),
                  },
                  {
                    len: 4,
                    message: this.$t('codeLenRule'),
                  },
                  {
                    pattern: /^[^\s]*$/,
                    message: this.$t('notEmpty'),
                  },
                ],
              },
            ]"
            :placeholder="this.$t('placeholderRegistCode')"
            style="width: 72%"
          >
            <a-icon
              slot="prefix"
              type="safety-certificate"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
          <a-button
            v-if="sendAuthCode"
            @click="sendEmailCode"
            class="codeimg"
            type="primary"
          >
            {{ $t(`getCode`) }}
          </a-button>
          <a-button v-else disabled class="codeimg" type="primary">
            {{ auth_time }}s
          </a-button>
        </div>
      </a-form-item>
      <!-- 输入密码 -->
      <a-form-item>
        <a-tooltip placement="right">
          <template slot="title"
            ><span class="tips">{{ $t(`passwordTip`) }}</span>
          </template>
          <a-input-password
            v-decorator="[
              'userPwd',
              {
                rules: [
                  {
                    required: true,
                    message: this.$t('passwordRule'),
                  },
                  {
                    validator: validateToNextPassword,
                  },
                  {
                    pattern: /^[^\s]*$/,
                    message: this.$t('notEmpty'),
                  },
                  {
                    
                    pattern:  /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![\u4e00-\u9fa5a]+$)(?![-_]+$)[\u4e00-\u9fa5a-zA-Z0-9_-]{8,32}$/,
                    message: this.$t('userPwdRule'),
                  },
                ],
              },
            ]"
            type="password"
            :placeholder="this.$t('placeholderRegistPwd')"
            @blur="testblur"
            style="line-height: 40px; width: 80%"
          >
            <a-icon
              slot="prefix"
              type="lock"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input-password>
        </a-tooltip>
      </a-form-item>
      <!-- 确认密码 -->
      <a-form-item>
        <a-tooltip placement="right">
          <template slot="title"
            ><span class="tips">{{ $t(`confirmTip`) }}</span>
          </template>
          <a-input-password
            v-decorator="[
              'confirm',
              {
                rules: [
                  {
                    required: true,
                    message: this.$t('confirmPasswordRule'),
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                  {
                    pattern: /^[^\s]*$/,
                    message: this.$t('notEmpty'),
                  },
                ],
              },
            ]"
            type="password"
            :placeholder="this.$t('placeholderRegistPwd2')"
            @blur="handleConfirmBlur"
            style="line-height: 40px; width: 80%"
          >
            <a-icon
              slot="prefix"
              type="lock"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input-password>
        </a-tooltip>
      </a-form-item>
      <a-form-item>
        <a-checkbox @change="onChange" :checked="checked"> </a-checkbox>
        <span class="prispan" @click="showModal"
          >&nbsp;&nbsp;{{ $t(`privacyPolicy`) }}</span
        >
      </a-form-item>

      <a-form-item>
        <a-button
          type="primary"
          html-type="submit"
          class="login-form-button"
          :disabled="disableConfirm"
        >
          {{ $t(`registCount`) }}
        </a-button>
      </a-form-item>
    </a-form>
    <div>
      <a-modal class="primodal" v-model="visible">
        <iframe class="ifclass" @load="ifonload" :src="privacyUrl"></iframe>
        <template slot="footer">
          <a-button key="back" @click="agreehideModal" type="primary">
            {{ $t(`agree`) }}
          </a-button>
          <a-button key="submit" @click="hideModal">
            {{ $t(`notAgree`) }}
          </a-button>
        </template>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { showmessage } from "../../../utils/showMessage";
import { selectlan } from "@/utils/selectLanguage";
import { encrypt } from "@/utils/aes";
export default {
  name: "",
  components: {},
  props: {
    paramdata: {
      type: String,
      defalut: "",
    },
  },
  data() {
    return {
      readonlyInput: true,
      checked: false,
      privacyUrl:
        "https://privacy.xmeye.net/xmeye/privacy_" +
        sessionStorage.getItem("languagevalue") +
        ".html",
      visible: false,
      languagevalue: sessionStorage.getItem("languagevalue"),
      auth_time: 0,
      sendAuthCode: true,
      disableConfirm: true,
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "register" });
  },
  computed: {
    changeLan: function () {
      return this.$store.state.changeLan;
    },
    changeData: function () {
      return this.paramdata;
    },
  },

  watch: {
    changeLan() {
      this.form.resetFields();
      this.privacyUrl =
        "https://privacy.xmeye.net/xmeye/privacy_" +
        sessionStorage.getItem("languagevalue") +
        ".html";
    },
    changeData() {
      this.form.resetFields();
    },
  },
  mounted() {
    // console.log(this.form.getFieldsValue(),'this.form')
  },
  destroyed() {},
  methods: {
    cancelReadOnly() {
      this.readonlyInput = false;
    },
    ifonload() {
      var isRunUrl = function (url) {
        return new Promise(function (resolve, reject) {
          var dom = document.createElement("link");
          dom.href = url;
          dom.rel = "stylesheet";
          document.head.appendChild(dom);
          dom.onload = function () {
            document.head.removeChild(dom);
            resolve();
          };
          dom.onerror = reject;
        });
      };

      let that = this;
      isRunUrl(this.privacyUrl).then(
        function (data) {
          //处理resolve的代码
          console.log("Promise被置为resolve", data);
        },
        function (data) {
          //处理reject的代码
          console.log("程序被置为了reject", data);
          that.privacyUrl = "https://privacy.xmeye.net/xmeye/privacy_en.html";
        }
      );
    },
    showModal() {
      this.visible = true;
      this.ifonload();
    },
    hideModal() {
      this.visible = false;
      this.checked = false;
      this.disableConfirm = !this.checked;
    },
    agreehideModal() {
      this.visible = false;
      this.checked = true;
      this.disableConfirm = !this.checked;
    },
    //
    onChange(e) {
      console.log(e, "eeeee");
      this.checked = !this.checked;
      this.disableConfirm = !this.checked;
    },
    //验证用户名唯一性
    checkUsernameUnique() {
      if (
        this.$refs.userName.value !== "" &&
        this.$refs.userName.value !== null &&
        this.$refs.userName.value !== undefined
      ) {
        let userName = this.$refs.userName.value;

        this.$http
          .post("/transfer/usercheck/v1", { name: encrypt(userName) })
          .then((res) => {
            if (res) {
              if (res.code == 4012) {
                this.$message.destroy();
                this.$message.error(this.$t("hadUser"));
              }
            } else {
              this.$message.destroy();
              this.$message.error(this.$t("rulenamefail"));
            }
          });
      }
    },
    // 验证邮箱唯一
    checkUserMailUnique() {
      if (
        this.$refs.email.value !== "" &&
        this.$refs.email.value !== null &&
        this.$refs.email.value !== undefined
      ) {
        let email = this.$refs.email.value;
        this.$http
          .post("/transfer/usercheckMail/v1", {
            mail: encrypt(email),
          })
          .then((res) => {
            if (res) {
              if (res.code == 4024) {
                // 4024邮箱未注册
                // this.$message.destroy();
                // this.$message.success(this.$t("" + showmessage(res)));
              } else {
                this.$message.destroy();
                this.$message.error(this.$t("" + showmessage(res)));
              }
            } else {
              this.$message.destroy();
              this.$message.error(this.$t("ruleemailfail"));
            }
          });
      }
    },
    // 发送验证码
    sendEmailCode() {
      if (
        this.$refs.email.value !== "" &&
        this.$refs.email.value !== null &&
        this.$refs.email.value !== undefined
      ) {
        let email = this.$refs.email.value;

        this.$http
          .post("/transfer/scmail/v1", {
            mail: encrypt(email),
            language: selectlan(),
            type: "re",
          })
          .then((res) => {
            if (res) {
              if (res.code == 2000) {
                this.$message.destroy();
                this.$message.success(this.$t("sendSuccess"));
                this.sendAuthCode = false;
                this.auth_time = 120;
                var auth_timetimer = setInterval(() => {
                  this.auth_time--;
                  if (this.auth_time <= 0) {
                    this.sendAuthCode = true;
                    clearInterval(auth_timetimer);
                  }
                }, 1000);
              } else if (res.code == 5005) {
                this.$message.destroy();
                this.$message.error(this.$t("mailFormatError"));
              } else if (res.code == 4023) {
                this.$message.destroy();
                this.$message.error(this.$t("hadMail"));
              } else if (res.code == 4402) {
                this.$message.destroy();
                this.$message.error(this.$t("mailSendMuch"));
              } else {
                this.$message.destroy();
                this.$message.error(this.$t("" + showmessage(res)));
              }
            } else {
              this.$message.destroy();
              this.$message.error(this.$t("getcodefail"));
            }
          });
      } else {
        this.$message.destroy();
        this.$message.error(this.$t("emailRule"));
      }
    },
    // 注册
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let send = {
            name: encrypt(values.username),
            mail: encrypt(values.userMail),

            verCode: values.code,
            confirmpass: encrypt(values.confirm),
            password: encrypt(values.userPwd),
          };

          this.$http.post("/transfer/userregMail/v1", send).then((res) => {
            if (res) {
              if (res.code == 2000) {
                this.$message.destroy();
                this.$message.success(this.$t("registerSuccess"));
                this.$router.push({ path: "/" });
              } else if (res.code == 4010) {
                this.$message.destroy();
                this.$message.error(this.$t("codeError"));
              } else if (res.code == 4012) {
                this.$message.destroy();
                this.$message.error(this.$t("hadUser"));
              } else if (res.code == 4017) {
                this.$message.destroy();
                this.$message.error(this.$t("" + showmessage(res)));
              } else if (res.code === 4048) {
                this.$message.destroy();
                this.$message.error(this.$t("codeErrorMuch"));
              } else {
                this.$message.destroy();
                this.$message.error(this.$t("" + showmessage(res)));
              }
            } else {
              this.$message.destroy();
              this.$message.error(this.$t("ruleregisterfail"));
            }
          });
        }
      });
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    testblur(e) {
      //添加密码验证规则
      console.log(e.target.value, "testblur");
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("userPwd")) {
        callback(this.$t(`confirmTip`));
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      if (value && this.confirmDirty) {
        form.validateFields(["confirm"], { force: true });
      }
      callback();
    },
  },
};
</script>

<style scoped>
.regist-box >>> .ant-input-affix-wrapper .ant-input {
  text-align: left !important;
}
.codeinput {
  width: 80%;
}
.primodal >>> .ant-modal {
  width: 100% !important;
  height: 98%;
  position: relative;
  top: 0px;
  /* overflow: auto; */
}
.primodal >>> .ant-modal .ant-modal-close {
  display: none;
}
.primodal >>> .ant-modal-body {
  padding: 0;
}
.primodal >>> .ant-modal-footer {
  border-top-width: 0px;
}
.ifclass {
  width: 100%;
  height: 94vh;
  overflow: auto;
}
.codeinput >>> .ant-input-affix-wrapper {
  float: left;
}
.prispan {
  color: #1890ff;
  cursor: pointer;
}
.codeimg {
  width: 104px;
  height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: right;
}
.regist-box >>> .ant-tabs-nav-container {
  font-size: 20px;
}
.regist-box >>> .ant-form-explain {
  text-align: left;
  margin-left: 10%;
}
.regist-box >>> .ant-form-item {
  margin-bottom: 7px;
}
.regist-box >>> .ant-form-item-children {
  display: flex;
  justify-content: center;
  height: 40px !important;
  align-items: center;
}
.regist-box >>> .ant-input {
  height: 40px !important;
  font-size: 16px;
}
.login-form-button {
  height: 40px !important;
  width: 80%;
  font-size: 16px !important;
}
.tips {
  display: block;
  font-size: 13px;
  max-width: 185px;
}
</style>
